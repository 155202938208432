import React from 'react';
import '../styles/Deportes.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

export default function Deportes() {
  return (
    <div className="deportes-container">
      <div className="deportes-content">
        <h1 className="deportes-title">Software de Inscripción a Deportes en Polideportivos</h1>
        <p className="deportes-description">
          El software de inscripción a deportes en polideportivos es una solución integral diseñada para simplificar
          y agilizar el proceso de registro y gestión de inscripciones para actividades deportivas en múltiples
          centros deportivos. Con esta herramienta, tanto los usuarios como los administradores pueden acceder
          a una plataforma intuitiva que permite explorar y seleccionar una amplia variedad de actividades deportivas
          disponibles en diferentes polideportivos.
        </p>

        <div className="deportes-benefits">
          <div className="benefits-users">
            <h2>Beneficios para los Usuarios</h2>
            <p>
              Desde la comodidad de su hogar o dispositivo móvil, los usuarios pueden explorar las opciones de deportes disponibles,
              revisar horarios y seleccionar las actividades que desean realizar.
            </p>
          </div>
          <div className="benefits-admins">
            <h2>Beneficios para los Administradores</h2>
            <p>
              Para los administradores de los polideportivos, el software proporciona herramientas de gestión centralizadas que permiten supervisar
              y administrar las inscripciones, asignar recursos y programar actividades de manera eficiente.
            </p>
          </div>
        </div>

        <div className="deportes-summary">
          <h2>Resumen</h2>
          <p>
            En resumen, el software de inscripción a deportes en polideportivos es una solución versátil y fácil de usar que mejora la accesibilidad, la eficiencia
            y la experiencia general tanto para los usuarios como para los administradores de los centros deportivos.
          </p>
          <p>¡Con esta herramienta, ponerte en forma y disfrutar del deporte nunca había sido tan fácil!</p>
        </div>
      </div>
    </div>
  );
}
