// src/components/Nosotros/Nosotros.js
import React from 'react';
import '../styles/Nosotros.css';
import image from '../images/Vym.png'; // Reemplaza con la ruta de tu imagen

const Nosotros = () => {
  return (
    <div className="nosotros-container">
      <div className="nosotros-section">
        <div className="nosotros-heading">Visión</div>
        <img src={image} alt="Vision" className="nosotros-image" />
      </div>
      <p className="nosotros-text">
        Ser una fundación innovadora en el desarrollo e investigación de conocimientos en tecnología, reconocida por la calidad humana y profesional y por nuestra contribución a la comunidad que permita disminuir la brecha entre las instituciones educativas y empresas.
      </p>
      <div className="nosotros-section">
        <div className="nosotros-heading">Misión</div>
        <img src={image} alt="Misión" className="nosotros-image" />
      </div>
      <p className="nosotros-text">
        Contribuir al desarrollo económico y social de la República Argentina, mediante la inclusión de jóvenes en el campo de la tecnología, promoviendo la generación de ideas, la inserción laboral de calidad y la cultura emprendedora.
      </p>
    </div>
  );
};

export default Nosotros;
