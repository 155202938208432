import React, { useState } from 'react'; // Importo React y el hook useState para manejar el estado en el componente.
import { useNavigate } from 'react-router-dom'; // Importo el hook useNavigate para manejar la navegación en la aplicación.
import '../styles/GovTech.css'; // Importo el archivo CSS con los estilos específicos para esta página.
import imageData from '../data/DataGovTech.js'; // Importo los datos de las imágenes y enlaces desde un archivo separado.
import carga from '../images/logo.png'; // Importo el icono de carga que se mostrará mientras se carga el iframe.

export default function GovTech() { // Declaro y exporto el componente GovTech.
  const [iframeSrc, setIframeSrc] = useState(''); // Declaro el estado iframeSrc para almacenar la URL del iframe.
  const [showIframe, setShowIframe] = useState(false); // Declaro el estado showIframe para controlar la visibilidad del iframe.
  const [isLoading, setIsLoading] = useState(false); // Declaro el estado isLoading para mostrar el icono de carga mientras el iframe se carga.
  const navigate = useNavigate(); // Inicializo el hook useNavigate para manejar la navegación programática.

  // Función que maneja el clic en una imagen.
  const handleImageClick = (image) => { // Recibo el objeto de la imagen que se hizo clic.
    if (image.link === '/deportes') { // Si el enlace de la imagen es '/deportes'
      navigate(image.link); // Navego a la ruta '/deportes'.
    } else { // Para todas las demás imágenes
      setIframeSrc(image.iframeSrc); // Establezco la URL del iframe a la URL correspondiente de la imagen.
      setShowIframe(true); // Muestro el iframe.
      setIsLoading(true); // Muestra el icono de carga mientras se carga el iframe.
    }
  };

  // Función para cerrar el iframe.
  const handleCloseIframe = () => {
    setShowIframe(false); // Oculto el iframe.
    setIframeSrc(''); // Limpio la URL del iframe.
    setIsLoading(false); // Oculto el icono de carga.
  };
  
  // Función que maneja el evento de carga del iframe.
  const handleIframeLoad = () => {
    setIsLoading(false); // Oculto el icono de carga cuando el iframe ha terminado de cargar.
  };

  return (
    <div className="govtech-container"> {/* Contenedor principal de la página */}
      <h3 className="title-second">GovTech</h3> {/* Título de la sección */}
      {showIframe && ( // Solo muestro el iframe si el estado showIframe es true
        <div className="iframe-overlay"> {/* Contenedor del iframe con fondo semitransparente */}
          <button 
            className="close-button" 
            onClick={handleCloseIframe}
          >
            X
          </button> {/* Botón para cerrar el iframe */}
          {isLoading && ( // Solo muestro el icono de carga si el estado isLoading es true
            <img 
              src={carga} 
              alt="Cargando" 
              className="loading-icon" 
            /> // Muestra el icono de carga mientras se carga el iframe
          )}
          <iframe
            src={iframeSrc} // URL del iframe que se debe mostrar
            width="100%" // Ancho del iframe
            height="100%" // Altura del iframe
            frameBorder="0" // Sin borde alrededor del iframe
            allowFullScreen // Permite al iframe entrar en modo de pantalla completa
            title="Canva Design" // Título del iframe
            onLoad={handleIframeLoad} // Maneja el evento de carga del iframe
          ></iframe>
        </div>
      )}
      <div className="img-grid-govtech"> {/* Contenedor para las imágenes */}
        {imageData.map((image, index) => ( // Itera sobre los datos de las imágenes
          <img
            key={index} // Clave única para cada imagen
            src={image.foto} // URL de la imagen
            alt={image.title} // Texto alternativo para la imagen
            className="imgs" // Clase CSS para aplicar estilos a la imagen
            onClick={() => handleImageClick(image)} // Pasa el objeto de la imagen al hacer clic
          />
        ))}
      </div>
    </div>
  );
}
