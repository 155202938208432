import React from 'react';
import '../styles/Cloud360.css';
import { Link } from 'react-router-dom';
import imageData from '../data/CardData';

export default function Cloud360() {
  return (
    <div className="cloud360-container">
      <div className="img-grid">
        {imageData.map((image, index) => (
          <Link key={index} to={image.link}>
            <img
              src={image.foto}
              alt={image.title}
              className="im1"
              loading="lazy"
              srcSet={`${image.foto}?w=200 200w, ${image.foto}?w=400 400w, ${image.foto}?w=800 800w`}
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
