import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Apps from './App';
import { BrowserRouter as Router } from 'react-router-dom';

// Crear la raíz del renderizado
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderizar la aplicación
root.render(
  <React.StrictMode>
    <Router>
      <Apps />
    </Router>
  </React.StrictMode>
);
