import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../styles/ModalImages.css';

Modal.setAppElement('#root');

const ImageModal = ({ isOpen, onRequestClose, images, loading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0);
    }
  }, [isOpen]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const isVideo = (path) => path.endsWith('.mp4') || path.endsWith('.mov');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <button className="modal-close-button" onClick={onRequestClose}>X</button>
      {loading ? (
        <p>Cargando imágenes...</p>
      ) : (
        <div className="modal-body">
          <div className="modal-preview">
            {images.map((image, index) => (
              isVideo(image.path) ? (
                <div
                  key={index}
                  className="video-thumbnail"
                  onClick={() => handleImageClick(index)}
                >
                  <span>▶</span>
                </div>
              ) : (
                <img
                  key={index}
                  src={`https://api2.fundacion360.com.ar/storage/${image.path}`}
                  alt={`Thumbnail ${index + 1}`}
                  className={`modal-thumbnail ${currentIndex === index ? 'active' : ''}`}
                  onClick={() => handleImageClick(index)}
                />
              )
            ))}
          </div>
          <div className="modal-image-container">
            {images.length > 0 && (
              <>
                <button className="modal-nav-button" onClick={handlePrevious}>←</button>
                <div className="modal-image-wrapper">
                  {isVideo(images[currentIndex].path) ? (
                    <video
                      src={`https://api2.fundacion360.com.ar/storage/${images[currentIndex].path}`}
                      controls
                      className="modal-media"
                      onError={(e) => e.target.src = 'https://via.placeholder.com/500'}
                    />
                  ) : (
                    <img
                      src={`https://api2.fundacion360.com.ar/storage/${images[currentIndex].path}`}
                      alt={`Image ${currentIndex + 1}`}
                      className="modal-image"
                      onError={(e) => e.target.src = 'https://via.placeholder.com/500'}
                    />
                  )}
                </div>
                <button className="modal-nav-button" onClick={handleNext}>→</button>
              </>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImageModal;
