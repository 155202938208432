import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NuevoNav.css'; // Asegúrate de crear este archivo y enlazarlo correctamente
import homeImg from '../images/hogar.png'
import img1 from '../images/contacto.png';
import img2 from '../images/nosotros.png';
import img3 from '../images/nube.png';
const NavBar = () => {
  //color imagenes: #78D47E
  return (
    <nav className="navbarr">
      <Link to="/" className="nav-link"><img src={homeImg} alt="home" /></Link>
      <Link to="/contact" className="nav-link"><img src={img1} alt="img1" /></Link>
      <Link to="/nosotros" className="nav-link"><img src={img2} alt="img2" /></Link>
      <Link to="/cloud360" className="nav-link"><img src={img3} alt="img3" /></Link>
    </nav>
  );
};

export default NavBar;
