import Img1 from '../images/salud.png';
import Img2 from '../images/horas_extras.png';
import Img3 from '../images/stock.png';
import Img4 from '../images/educacion.png';
import Img5 from '../images/partes-diarios.png';
import Img6 from '../images/deportes.png';

const dataGov = [
  {
    "iframeSrc": "https://www.canva.com/design/DAF5gczB3KQ/8TRZUUCUlhlUWY45kN64Cw/view?embed",
    "link": "/salud",
    "foto": Img1
  },
  {
    "iframeSrc": "https://www.canva.com/design/DAF_OcWWuJU/CCQs6PM27r0catThko91bA/view?embed",
    "link": "/horas-extras",
    "foto": Img2
  },
  {
    "iframeSrc": "https://www.canva.com/design/DAGFOVUeFvI/vKadV4V8jNE_iL5Kx4Af9A/watch?embed",
    "link": "/control-stock",
    "foto": Img3
  },
  {
    "iframeSrc": "https://www.canva.com/design/DAGFOqA8SCM/Yuyk0hBnetdI7DH51bf5eg/watch?embed",
    "link": "/educacion",
    "foto": Img4
  },
  {
    "iframeSrc": "https://www.canva.com/design/DAGFOqhJrdE/5tej-ObU3WRvoMLhIPtZIw/view?embed",
    "link": "/partes-personal",
    "foto": Img5
  },
  {
    "iframeSrc": "https://www.canva.com/design/DAGFOqhJrdE/5tej-ObU3WRvoMLhIPtZIw/view?embed",
    "link": "/deportes",
    "foto": Img6
  }
]
export default dataGov;