import '../styles/Educacion.css'; // Importo el archivo de estilos específico para esta página.
import { Link } from 'react-router-dom'; // Importo el componente Link de react-router-dom para manejar la navegación.
import imageData from '../data/DataEdu'; // Importo el archivo de datos que contiene la información de las tarjetas.

export default function Educacion() {
  return (
    <div className="edu-container">
      <div className="img-grid-edu">
        {imageData.map((image, index) => (
          <Link key={index} to={image.link}> {/* Enlazo cada imagen a su link correspondiente */}
            <img src={image.foto} alt={image.title} className="img-edu" /> {/* Muestro cada imagen con sus datos correspondientes */}
          </Link>
        ))}
      </div>
    </div>
  );
}
