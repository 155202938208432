import React from 'react';
import '../../styles/programas/PuenteJoven.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

export default function Deportes() {
  return (
    <div className="puente-container">
      <div className="puente-content">
        <h1 className="puente-title">Programa Puente Joven</h1>
        <p className="puente-description">
            Impulsado por Fundación 360, el PROGRAMA PUENTE JOVEN se presenta como un innovador proyecto
            que fusiona la tecnología con la agricultura a través de la hidroponía domotizada...
        </p>
        <h1>Hidroponía Domotizada: Una Técnica Revolucionaria</h1>
        <p>
            Esta técnica revolucionaria, que prescinde del suelo para el cultivo de plantas, ha sido implementada
            con éxito en la Escuela Secundaria Municipal de Estudios Técnicos N°1...
        </p>
        <h1>Colaboración y Coordinación</h1>
        <p>
            En colaboración directa con la escuela, el equipo de Fundación 360 lidera el desarrollo
            de la hidroponía domotizada, utilizando conocimientos de robótica y domótica para automatizar y controlar los procesos de cultivo...
        </p>
        <h1>Apoyo de Fundación 360</h1>
        <p>
            El respaldo de Fundación 360 ha sido fundamental para el éxito de estas iniciativas,
            proporcionando los recursos tecnológicos necesarios y apoyando la investigación y desarrollo en el campo de la hidroponía...
        </p>
        <h1>Expansión y Replicación</h1>
        <p>
            Gracias a esta colaboración, se han realizado experiencias piloto durante el ciclo lectivo 2019,
            sentando las bases para la expansión y replicación de este modelo en otros establecimientos educativos municipales en 2020...
        </p>
      </div>
    </div>
  );
}
