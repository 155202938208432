// src/data/dataProgramas.js
import Img1 from '../images/fomento1.png';
import Img2 from '../images/fomento2.png';
import Img3 from '../images/fomento3.png';

const dataProgramas = [
  {
    link: "/desayuno-tec",
    foto: Img1,
  },
  {
    link: "/dvtic",
    foto: Img2,
  },
  {
    link: "/puente-joven",
    foto: Img3,
  }
];

export default dataProgramas;
