// src/components/Programas.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import dataProgramas from '../data/DataProg';
import '../styles/Programas.css'; // Archivo CSS para estilos

const Programas = () => {
  return (
    <div className="programas-container">
      <h3 className="title-programas">
        Programas de Fomento de Vocaciones TIC e Inserción Laboral
      </h3>
      <div className="programas-grid">
        {dataProgramas.map((programa, index) => (
          <Link key={index} to={programa.link} className="programa-item">
            <img src={programa.foto} alt={`Programa ${index + 1}`} className="programa-image" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Programas;
