import React, { useState, useEffect } from 'react';
import '../styles/Inicio.css'; // Importa el CSS personalizado
import videoSrc from '../images/bienvenidos.mp4'; // Importa el video de bienvenida
import wpIcn from '../images/whatsapp.png'; // Importa el ícono de WhatsApp
import CmpsIcn from '../images/logo-campus.png'; // Importa el logo del campus
import { useNavigate } from 'react-router-dom'; // Importa useNavigate de React Router
import upArrow from '../images/upArrow.png';
import downArrow from '../images/downArrow.png';
import ImageModal from './ModalImages'; // Importa el componente del modal
import api from '../services/api';
import dataGov from '../data/CardData.js'; // Importa los datos de las imágenes

function Inicio() {
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar si el modal está abierto o cerrado
  const [images, setImages] = useState([]); // Estado para almacenar las imágenes
  const [loading, setLoading] = useState(true); // Estado para manejar la carga de las imágenes
  const [expanded, setExpanded] = useState(false); // Estado para manejar la expansión de los botones en pantalla pequeña

  const navigate = useNavigate(); // Hook para manejar la navegación

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data } = await api.get('/images'); // Solicita las imágenes desde la API
        setImages(data); // Actualiza el estado con las imágenes recibidas
      } catch (error) {
        console.error('Error al obtener las imágenes:', error); // Manejo de errores
      } finally {
        setLoading(false); // Indica que la carga ha finalizado
      }
    };

    fetchImages(); // Llama a la función para obtener las imágenes
  }, []); // El array vacío [] asegura que este efecto solo se ejecute una vez al montar el componente

  const openModal = () => setModalOpen(true); // Función para abrir el modal
  const closeModal = () => setModalOpen(false); // Función para cerrar el modal

  // Función para manejar la expansión de los botones
  const toggleExpand = () => setExpanded(!expanded);

  return (
    <section className="bSection">
      <div className="bLeft" id="bLeft">
        <h1 className="bTittle" id="bTittle">{"{ Fundación 360  }"} </h1>

        <p className="bText" id="bText">
          La Fundación 360 desarrolla programas que integran a los ciudadanos en el universo de la Economía del Conocimiento, el Gobierno Digital y las Industrias 4.0. Con más de 200 casos de éxito en Empresas, Gobierno y Asociaciones contribuye también a los espacios formativos de recursos humanos y programas de Responsabilidad Social Empresaria, habiendo capacitado a la fecha alrededor de 25.000 alumnos en Competencias Digitales, Robótica y Domótica.
        </p>
        {/* Botón para abrir el modal de la galería de imágenes */}
        <button onClick={openModal} className="view-images-button">Ver Galeria</button>
      </div>
      <div className="bRigth">
        {/* Video de bienvenida */}
        <video className="bVideo" src={videoSrc} muted autoPlay loop playsInline preload="auto"></video>
        {/* Enlace a WhatsApp */}
        <a href="https://wa.me/message/Q32NSDP5QIQGN1" target="_blank" className="whatsapp-icon">
          <img src={wpIcn} alt="WhatsApp"/>
        </a>
        {/* Enlace al campus */}
        <a href="https://campus.fundacion360.com.ar/?" target="_blank" className="campus-icon">
          <img src={CmpsIcn} alt="CampusFundacion"/>
        </a>
      </div>

      {/* Modal para mostrar las imágenes */}
      <ImageModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        images={images}
        loading={loading}
      />

      {/* Flecha para expandir los botones */}
      <div className={`expand-arrow ${expanded ? 'expanded' : ''}`} onClick={toggleExpand}>
        {expanded ? <img src={downArrow} alt="upIcn"/> : <img src={upArrow} alt="upIcn"/> }
      </div>

      {/* Contenedor para las imágenes adicionales */}
      <div className={`image-buttons-container ${expanded ? 'expanded' : ''}`}>
        {dataGov.map((item, index) => (
          <img
            key={index}
            src={item.foto}
            alt={`Button ${index}`}
            className={`image-button ${expanded ? 'expanded' : ''}`}
            onClick={() => navigate(item.link)} // Usando navigate para la redirección
          />
        ))}
      </div>
    </section>
  );
}

export default Inicio;
