import React from 'react';
import '../styles/Rse.css';
import rseImage from '../images/acceso.png'; // Importa la imagen que has subido.
import { Link } from 'react-router-dom';
export default function Rse() {
  return (
    <div className='rse-container'>
      <h3 className="title-second-rse">RSE</h3>
      <div className='rse-content'>
        <p>En Fundación 360, entendemos que la responsabilidad social no es solo una obligación, sino una oportunidad para construir un impacto positivo.</p>
        <p>Nos esforzamos por acercar las acciones y compromisos de las empresas al público, creando un espacio donde la transparencia y la responsabilidad empresarial sean reconocidas y celebradas.</p>
        <Link target="_blank" to="https://rse.fundacion360.com.ar/">
          <img src={rseImage} alt='Imagen RSE' className='rse-image' /> {/* Imagen en el centro */}
        </Link>
      </div>
    </div>
  );
}
