// src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png'; // Importa el logo
import nubb from '../images/cloud360.svg'; // Importa el ícono de nube de Font Awesome
import '../styles/Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Renombrado de location a currentLocation
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  
  //veo donde estoy ubicado
  const getNavLinkClass = (path) => location.pathname === path ? 'active' : '';
  
  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="brand-logo">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`} onClick={handleClose}>
          <li><Link to="/" className={getNavLinkClass('/')}><span>Inicio</span></Link></li>
          <li><Link to="/contact" className={getNavLinkClass('/contact')}><span>Contacto</span></Link></li>
          <li className="cloud-link">
            <Link to="/cloud360">
              <span className='span-cloud'>Cloud 360</span>
              <img src={nubb} className='nub-icon'/>
            </Link>
          </li>

  <li className="cloud-link">
  </li>
</ul>
        <div className={`menu-toggle ${isOpen ? 'open' : ''}`} id="mobile-menu" onClick={handleToggle}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={handleClose}></div>
    </nav>
  );
}

export default Navbar;
