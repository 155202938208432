import Img1 from '../images/campus.png';
import Img2 from '../images/prueba_taller.png';
import Img3 from '../images/modalidades.png';

const dataEdu = [
  {
    "link": "https://campus.fundacion360.com.ar/?",
    "foto": Img1
  },
  {
    "link": "/talleres",
    "foto": Img2
  },
  {
    "link": "/modalidades",
    "foto": Img3
  }
]
 export default dataEdu;