import React from 'react';
import '../../styles/programas/Dvtic.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

export default function Deportes() {
  return (
    <div className="dvtic-container">
      <div className="dvtic-content">
        <h1 className="dvtic-title">DV-TIC</h1>
        <p className="dvtic-description">
        El Taller Despertar Vocaciones TIC es un programa educativo diseñado para inspirar y fomentar el interés
        de estudiantes y jóvenes en el campo de las Tecnologías de la Información y la Comunicación (TIC).
        Este taller busca motivar a los participantes a explorar y considerar carreras en el ámbito de la tecnología, la informática y la programación.
        </p>
        <p>
        Con el objetivo de proporcionar una experiencia enriquecedora y estimulante, el Taller Despertar Vocaciones TIC
        se erige como un espacio donde la innovación y el aprendizaje se fusionan para abrir nuevas puertas en el fascinante mundo
        de las Tecnologías de la Información y la Comunicación (TIC). A través de actividades dinámicas y prácticas, los participantes
        tienen la oportunidad de sumergirse en conceptos clave de la informática y la programación, descubriendo así su potencial
        y despertando su curiosidad por las infinitas posibilidades que ofrece este campo en constante evolución.
        </p>
        <p>
        Este taller no solo busca transmitir conocimientos técnicos, sino también cultivar habilidades blandas como el pensamiento crítico,
        la resolución de problemas y el trabajo en equipo, elementos esenciales para triunfar en el panorama actual de la tecnología
        </p>
        <p>¡Únete a nosotros y despierta tu vocación por las TIC!</p>
      </div>
    </div>
  );
}
