import React from 'react';
import '../styles/Contacto.css';
import mailIcon from '../images/mail.png';
import celularIcon from '../images/celular.png';
import direccionIcon from '../images/direccion.png';
import logo from '../images/logo.png';
import facebookIcon from '../images/facebook.png';
import instagramIcon from '../images/instagram.png';
import wpIcon from '../images/wp.png';

const Contact = () => {
  return (
    <section className="uSection contactSection">
      <div className="fRight">
        <div className="fContacto" >
          <img className="fCIcon" src={mailIcon} alt="Email Icon" />
          <div className="fData">
            <h2 className="fCTitle">E-MAIL</h2>
            <p className="fCText">info@fundacion360.com.ar</p>
          </div>
        </div>
        <div className="fContacto">
          <img className="fCIcon" src={celularIcon} alt="Cellphone Icon" />
          <div className="fData">
            <h2 className="fCTitle">CELULAR</h2>
            <p className="fCText">+54 (9) 223 550-9658</p>
          </div>
        </div>
        <div className="fContacto">
          <img className="fCIcon" src={direccionIcon} alt="Address Icon" />
          <div className="fData">
            <h2 className="fCTitle">DIRECCIÓN</h2>
            <p className="fCText">Chaco 1670</p>
          </div>
        </div>
      </div>
      <img className="fLogo" src={logo} alt="Fundación 360 Logo" />
      <div className="sMedia">
        <a href="https://www.facebook.com/Fundacion360?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <img className="fSocial" src={facebookIcon} alt="Facebook Icon" />
        </a>
        <a href="https://instagram.com/fundacion360?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
          <img className="fSocial" src={instagramIcon} alt="Instagram Icon" />
        </a>
        <a href="https://wa.me/message/Q32NSDP5QIQGN1" target="_blank" rel="noopener noreferrer">
          <img className="fSocial" src={wpIcon} alt="WhatsApp Icon" />
        </a>
      </div>
    </section>
  );
};

export default Contact;
