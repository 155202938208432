// App.jsx
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Contacto from './components/Contacto';
import Inicio from './components/Inicio';
import NuevoNav from './components/NuevoNav';
import Nosotros from './components/Nosotros';
import Modalidades from './components/Modalidades';
import Cloud360 from './components/Cloud360';
import GovTech from './components/GovTech';
import RSE from './components/Rse';
import HeaderCloud from './components/HeaderCloud';
import Educacion from './components/Educacion';
import Sponsors from './components/Sponsors';
import Deportes from './components/Deportes';
import Talleres from './components/Talleres';
import './styles/App.css';
import Programas from './components/Programas';
import DvTic from './components/programas/Dvtic';
import PuenteJoven from './components/programas/PuenteJoven';
import DesayunoTec from './components/programas/DesayunoTec';
import Novedades from './components/Novedades';
//combinacion de todo
function App() {
  return (
    <div className="App">
      <Routes>
        {/* Usamos el componente de carga perezosa Cloud360Loader para la ruta /cloud360 */}
        <Route path="/cloud360" element={<><NuevoNav /><HeaderCloud/><Cloud360 /></>}/>
        <Route path='/govtech' element={<><NuevoNav /><HeaderCloud/><GovTech/></>}/>
        <Route path='/deportes' element={<><NuevoNav /><HeaderCloud/><Deportes/></>}/>
        <Route path='/modalidades' element={<><NuevoNav /><HeaderCloud/><Modalidades/></>}/>
        <Route path='/educacion' element={<><NuevoNav /><HeaderCloud/><Educacion/></>}/>
        <Route path='/sponsors' element={<><NuevoNav /><HeaderCloud/><Sponsors/></>}/>
        <Route path='/rse' element={<><NuevoNav/><HeaderCloud/><RSE /></>}/>
        <Route path='/programas' element={<><NuevoNav/><HeaderCloud/><Programas /></>}/>
        <Route path='/desayuno-tec' element={<><NuevoNav/><HeaderCloud/><DesayunoTec /></>}/>
        <Route path='/dvtic' element={<><NuevoNav/><HeaderCloud/><DvTic/></>}/>
        <Route path='/novedades' element={<><NuevoNav/><HeaderCloud/><Novedades/></>}/>
        <Route path='/puente-joven' element={<><NuevoNav/><HeaderCloud/><PuenteJoven/></>}/>
        <Route path='/talleres' element={<><NuevoNav/><HeaderCloud/><Talleres/></>}/>
        <Route path="*" element={
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/contact" element={<Contacto />} />
            </Routes>
          </>
        }/>
      </Routes>
    </div>
  );
}
export default App;