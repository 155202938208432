import Img1 from '../images/robobloq.png';
import Img2 from '../images/dfrobot.png';
import Img3 from '../images/pcbox.png';
import Img4 from '../images/nucleo.png';
import Img5 from '../images/neutron.png';

const dataSpon = [
  {
    "link": "https://neutron.com.ar/",
    "foto": Img5,
  },
  {
    "link": "https://www.gruponucleo.com.ar/",
    "foto": Img4,
  },
  {
    "link": "https://www.pcboxargentina.com.ar/",
    "foto": Img3,
  },
  {
    "link": "https://www.dfrobot.com/",
    "foto": Img2,
  },
  {
    "link": "https://www.robobloq.com/",
    "foto": Img1,
  }
];

export default dataSpon;
