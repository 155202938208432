import React from 'react';
import '../../styles/programas/DesayunoTec.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

export default function Deportes() {
  return (
    <div className="desayuno-container">
      <div className="desayuno-content">
        <h1 className="desayuno-title">Programa desayuno tecnológico</h1>
        <p className="desayuno-description">
          El PROGRAMA DESAYUNO TECNOLÓGICO, impulsado por #Fundación360, se centra en proporcionar oportunidades de aprendizaje y
          desarrollo en tecnología a jóvenes...
        </p>
        <h1>Colaboración con Fundación Pupi y el Comedor de Hermana Marta</h1>
        <p>
            Este programa ofrece talleres de robótica y programación como parte de un desayuno educativo.
            Estas actividades no solo nutren el conocimiento tecnológico de los participantes, sino que también
            promueven valores de inclusión y equidad, brindando herramientas que sin duda beneficiarán a
            estos jóvenes en su futuro.
        </p>
        <h1>¡Únete al Desayuno Tecnológico y prepárate para un viaje emocionante hacia el mundo digital! 🤖📚</h1>
      </div>
    </div>
  );
}
