import Img1 from '../images/govtech.png';
import Img2 from '../images/rse.png';
import Img3 from '../images/eventos.png';
import Img4 from '../images/edu.png';
import Img5 from '../images/prog.png';
import Img6 from '../images/spon.png';

const cardData =[
  {
    "link": "/govtech",
    "foto": Img1
  },
  {
    "link": "/rse",
    "foto": Img2
  },
  {
    "link": "/novedades",
    "foto": Img3
  },
  {
    "link": "/educacion",
    "foto": Img4
  },
  {
    "link": "/programas",
    "foto": Img5
  },
  {
    "link": "/sponsors",
    "foto": Img6
  }
]

export default cardData;