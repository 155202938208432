import '../styles/Sponsors.css'; // Importo el archivo de estilos específico para esta página.
import { Link } from 'react-router-dom'; // Importo el componente Link de react-router-dom para manejar la navegación.
import imageData from '../data/DataSpon'; // Importo el archivo de datos que contiene la información de las tarjetas.

export default function rse() {
  return (
    <div className="spon-container">
      <div className='title-second-spon'>
      <a className='title-sponsor'>Empresas que nos Apoyan</a>
      </div> 
      <div className='description-spon-container'> 
      <p className="sponsors-description">
      Estamos agradecidos por el apoyo y la colaboración de estas empresas. 
      Su compromiso es fundamental para el éxito de nuestros proyectos. A
      preciamos sinceramente su contribución y su confianza en nuestra misión.
        </p>
        </div>
      <div className="img-grid-spon">
        {imageData.map((image, index) => (
          <Link target="_blank" key={index} to={image.link} className="spon"> {/* Agrega clase según el nivel */}
            <img src={image.foto} alt={image.title} className="img-spon" /> {/* Muestro cada imagen con sus datos correspondientes */}
          </Link>
        ))}
      </div>
    </div>
  );
}
