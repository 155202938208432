import React from 'react';
import '../styles/HeaderCloud.css';
import logofund from '../images/logo.png'; // Importo la imagen del logotipo de la fundación.
import logo from '../images/cloud360.svg'; // Importo la imagen del logotipo de Cloud360.
function HeaderCloud() {
  return (
    <div className="header-container">
    <div className="title-with-logo">
      <img src={logo} alt="Logof" className="logocloud" /> {/* Muestro la imagen del logotipo de Cloud360 */}
      <h3 className="header-title">Ecosistema de Productividad en la Nube</h3> {/* Título de la sección */}
      <img src={logofund} alt="Logof" className="logofund" /> {/* Muestro la imagen del logotipo de la fundación */}
    </div>
    <div className="logo-disp-container">
      <img src={logofund} alt="Logof" className="logo-disp" /> {/* Nuevo logotipo de la fundación */}
    </div>
    </div>
  );
}

export default HeaderCloud;
