import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import '../styles/Talleres.css';
import api from '../services/api';
import iconoCargaf from '../images/logo.png'; // Asegúrate de importar el icono de carga

const precargarImagenes = (imagenes) => {
  return Promise.allSettled(
    imagenes.map((src) => 
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      })
    )
  );
};

const TalleresList = () => {
  const [workshops, setWorkshops] = useState([]);
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);

  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await api.get('/workshops'); // Llamada protegida
        const fetchedWorkshops = response.data;

        const imageUrls = fetchedWorkshops.map(workshop => 
          `https://api2.fundacion360.com.ar/storage/${workshop.image_path.replace('public/', '')}`
        );
        
        await precargarImagenes(imageUrls);

        setWorkshops(fetchedWorkshops);
        setAreImagesLoaded(true);

      } catch (error) {
        console.error('Error al obtener los talleres:', error);
      }
    };

    fetchWorkshops();
  }, []);

  return (
    <>
      {!areImagesLoaded && (
        <div className="loading-container">
          <img src={iconoCargaf} alt="Loading" className="loading-icon" />
        </div>
      )}
      <div className="list-container">
        {areImagesLoaded && workshops.map(workshop => (
          <div key={workshop.id} className="list-item">
            <img
              src={`https://api2.fundacion360.com.ar/storage/${workshop.image_path.replace('public/', '')}`}
              alt={workshop.title}
              onError={(e) => e.target.src = 'https://via.placeholder.com/100'}
              loading="lazy"
            />
            <div className="list-content">
              <h2>{workshop.title}</h2>
              <ReactMarkdown>{workshop.description}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TalleresList;
