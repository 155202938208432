import React, { useEffect, useState } from 'react';
import { MantineProvider, Button } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css'; 
import '../styles/Novedades.css'; 
import ReactMarkdown from 'react-markdown';
import api from '../services/api'; 
import iconoCargaf from '../images/logo.png';

const Novedades = () => {
  const [novedades, setNovedades] = useState([]);
  const [selectedNovedad, setSelectedNovedad] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNovedades = async () => {
      try {
        const { data } = await api.get('/novedades');
        setNovedades(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error al obtener las novedades:', error);
        setIsLoading(false);
      }
    };

    fetchNovedades();
  }, []);

  const handleOpenModal = (novedad) => {
    setSelectedNovedad(novedad);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedNovedad(null);
  };

  const slides = novedades.map((novedad) => (
    <Carousel.Slide key={novedad.id}>
      <div className="novedad-slide">
        <div className="novedad-image-container">
          <img
            src={`https://api2.fundacion360.com.ar/storage/${novedad.imagen_principal.replace('public/', '')}`}
            alt={novedad.titulo}
            onError={(e) => (e.target.src = 'https://via.placeholder.com/500')}
            className="novedad-image"
          />
        </div>
        <div className="novedad-content">
          <h2>{novedad.titulo}</h2>
          <div className="novedad-description">
            <ReactMarkdown>{novedad.descripcion}</ReactMarkdown>
          </div>
          <Button className="read-more-button" onClick={() => handleOpenModal(novedad)}>Leer más</Button>
        </div>
      </div>
    </Carousel.Slide>
  ));

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <div>
        {isLoading ? (
          <div className="loading-container">
            <img src={iconoCargaf} alt="Loading" className="loading-icon" />
          </div>
        ) : (
          <div className="novedades-slider-container">
            <Carousel
              withIndicators
              loop
              slideSize="80%"
              height="auto"
              slideGap="md"
              align="center"
              slidesToScroll={1}
              autoplay={true}
            >
              {slides}
            </Carousel>
          </div>
        )}
  
        {modalOpen && selectedNovedad && (
          <div className="modal-nove-overlay" onClick={handleCloseModal}>
            <div className="modal-nove-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-close-nove-button" onClick={handleCloseModal}>X</button>
              <div className="modal-nove-text">
                <h2>{selectedNovedad.titulo}</h2>
              </div>
              <div className="modal-nove-image">
                <img
                  src={`https://api2.fundacion360.com.ar/storage/${selectedNovedad.imagen_principal.replace('public/', '')}`}
                  alt={selectedNovedad.titulo}
                  onError={(e) => (e.target.src = 'https://via.placeholder.com/500')}
                  className="modal-nove-image-img"
                />
              </div>
              <div className="modal-nove-text">
                <ReactMarkdown>{selectedNovedad.texto_completo}</ReactMarkdown>
              </div>
            </div>
          </div>
        )}
      </div>
    </MantineProvider>
  );
};

export default Novedades;
