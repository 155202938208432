import React from 'react';
import '../styles/Modalidades.css';
import videoModalidades from "../images/modalidades.mp4";

function Modalidades() {
  return (
    <div className="modalidades-container">
      <div className="modalidades-text">
        <h1>Educación</h1>
        <div className="modalidades-info">
          <div className="modalidades-item">
            <h2>Presencial</h2>
            <p>Los alumnos asisten a las clases en nuestras instalaciones.</p>
          </div>
          <div className="modalidades-item">
            <h2>Híbrida</h2>
            <p>El aprendizaje se lleva a cabo de manera presencial en nuestras instalaciones o de manera online, registrándose en el campus y pudiendo acceder a las clases en vivo desde donde estés.</p>
          </div>
          <div className="modalidades-item">
            <h2>Online</h2>
            <p>Accediendo al campus verán todas las clases disponibles.</p>
          </div>
        </div>
      </div>
      <div className="modalidades-video">
        <video autoPlay loop muted playsInline>
          <source src={videoModalidades} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
      </div>
    </div>
  );
}

export default Modalidades;
